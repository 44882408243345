import React, { useEffect, useRef, useState } from "react"
import { PageProps } from "gatsby"

import classNames from 'classnames';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { StaticImage } from "gatsby-plugin-image"

import "../../styles/mclaren.css"
import ProjectHero from "../../components/ProjectHero"
import ProjectTitle from "../../components/ProjectTitle";


const McLarenPortalPage = (props: PageProps) => {
  const swiperRef = useRef();  
  const activeRef = useRef(null);

  const [showDRS, setShowDRS] = useState(false);
  const [showRaceCommentary, setShowRaceCommentary] = useState(false);
  const [showSectors, setShowSectors] = useState(false);

  gsap.config();
  gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger);

  ScrollTrigger.defaults({ ignoreMobileResize: true });

  let isTransitioning = false;
  function toggleRootClass() {
    const root = document.querySelector('.splash');

    if (!isTransitioning) {
      root.classList.add('enable');
      isTransitioning = true
      
      setTimeout(() => {
        root.classList.remove('enable');
        isTransitioning = false
      }, 1100)
    }
  }

  let interval;

  const resetCounter = (currentSlide) => {
    const counter = currentSlide.querySelector('.countdown span')

    if (counter) {
      counter.style.width = '100%';
    }
  }

  const stopAllVideos = (currentSlide, currentVideo = null) => {
    document.querySelectorAll('video').forEach((video) => {
      if (currentVideo !== video) {
        video.pause();
        video.currentTime = 0;
        resetCounter(currentSlide);
      }
    })
  }

  const playVideo = (currentSlide, currentVideo, slide) => {
    currentVideo.play();

    const counter = currentSlide.querySelector('.countdown span');
    if (counter) {
      clearInterval(interval);
      interval = setInterval(() => {
        counter.style.width = `${100 - ((currentVideo.currentTime / currentVideo.duration) * 100)}%`
      }, 10)

      currentVideo.onended = () => {
        if (slide === 3) {
          swiperRef.current?.slideTo(0)
        } else {
          swiperRef.current?.slideTo(slide + 1)
        }
      }
    }
  }

  const activeSlide = (slide = '') => {
    let activeButton = document.querySelector(`.slide-button.is-active`);
    const currentSlide = document.querySelector(`[data-slide="${slide}"]`);
    if (currentSlide) {
      const currentVideo = currentSlide.querySelector('video');
      
      if (currentVideo) {
        setTimeout(() => stopAllVideos(currentSlide, currentVideo), 600)
        playVideo(currentSlide, currentVideo, slide)
      }
    }

    if (slide !== '') {
      activeButton = document.querySelector(`.slide-button--${slide}`);
      const buttons = document.querySelectorAll(`.slide-button`);
      buttons.forEach((button) => button.classList.remove('is-active'))
      activeButton?.classList.add('is-active')
    }

    const buttonWidth = activeButton?.getBoundingClientRect().width;
    const buttonOffset = activeButton?.getBoundingClientRect().left;
    activeRef.current.style.left = `${buttonOffset}px`;
    activeRef.current.style.width = `${buttonWidth}px`;
  }

  useEffect(() => {
    const blocks = gsap.utils.toArray('.mclaren-content-block');
    blocks.forEach((block, i) => {
        const asset = block.querySelector(".asset");
        
        gsap.to(asset, {
          y: () => asset.offsetHeight - block.offsetHeight,
          ease: "none",
          scrollTrigger: {
            trigger: block,
            scrub: true,
            pin: false,
            invalidateOnRefresh: true
          },
        }); 

      gsap.fromTo(
        block,
        { 
          scale: 0.8,
          opacity: 0, 
        },
        {
          scale: 1,
          opacity: 1,
          ease: "none",
          force3D: true,
          scrollTrigger: {
            // pin: parent,
            trigger: block,
            start: "top bottom",
            end: "top center",
            pinType: "transform",
            scrub: 0.8,
          }
        }
      );
    });
    // window.addEventListener('resize', () => activeSlide())
  

    return () => {
      // window.removeEventListener('resize', () => activeSlide())
    };
  }, [])


  return (
    <Layout page="work" route="/portfolio/mclaren-portal">
      <Seo 
        image={'/mclaren/cover-portal.jpg'}
        title="McLaren Portal by Alistair Bancroft"
        description={'Redesigning McLaren Portal: A Gateway to Racing Excellence'}
      />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css"
      />
      <div className="mclaren-namespace">
        <ProjectHero
          background="mclaren"
          agency={'ekino'}
          body={[
            "In 2020, McLaren underwent a visual transformation with a sharp-angled \"M\" in its logo, coinciding with the launch of the high-performance MCL35. Our task was to synchronize the portal's design with this bold branding shift.",
            "Drawing inspiration from the dynamic contours of the MCL35 and the angular nuances of the updated logo, we meticulously developed a sleek and intuitive portal that mirrors the speed and precision synonymous with McLaren's Formula 1 prowess.",
          ]}
          engagement={'2020'}
          technologies={'Javascript, HTML'}
          url={{
            href: 'https://www.mclaren.com',
            label: 'www.mclaren.com',
          }}
          position={'Front End Lead / ekino'}
          project={{
            label: 'McLAREN PORTAL',
          }}
          title={'Project: McLaren Racing Portal'}
          subtitle={"Redesigned McLaren Portal: A Gateway to Racing Excellence"}
        />
       
         

        <section data-background="mclaren" className="font-sans h-screen overflow-hidden relative w-full">
          <div className="h-screen object-cover w-full splash">
            <div className="navbar z-40">
              <div className="flex h-full items-center justify-center px-5 md:px-6 relative w-[80%] md:w-full z-50">
                <button className="burger">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <button 
                  className={`font-thin mx-4 lg:mx-8 text-sm text-white tracking-wider uppercase slide-button slide-button--0 is-active`} 
                  onClick={(e) => {
                    swiperRef.current?.slideTo(0)
                  }}
                >Racing</button>
                <button 
                  className={`font-thin mx-4 lg:mx-8 text-sm text-white tracking-wider uppercase slide-button slide-button--1`}
                  onClick={(e) => {
                    swiperRef.current?.slideTo(1)
                  }}
                >Automotive</button>
                <button 
                  className={`font-thin mx-4 lg:mx-8 text-sm text-white tracking-wider uppercase slide-button slide-button--2`} 
                  onClick={(e) => {
                    swiperRef.current?.slideTo(2)
                  }}
                >Group</button>
                <button 
                  className={`font-thin mx-4 lg:mx-8 text-sm text-white tracking-wider uppercase slide-button slide-button--3`} 
                  onClick={(e) => {
                    swiperRef.current?.slideTo(3)
                  }}
                >Careers</button>
                <span className="absolute bg-white block bottom-0 h-1 z-10" ref={activeRef}></span>
              </div>
              <div className="absolute top-6 md:top-5 right-4 w-[100px] md:w-[160px]">
                <svg className="w-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.01 3.55" role="img">
                  <path d="M12.64,2.91c0,.1-.04,.16-.17,.16h-.84c-.11,0-.17-.03-.17-.13s.04-.15,.17-.15h1.01v.12Zm3.69-.4v-.11c0-.1,.04-.16,.18-.16h.84c.12,0,.17,.03,.17,.14,0,.09-.05,.13-.16,.13h-1.03Zm2.2-.74v1.77h.85v-1.29h.69c.23,0,.35,.07,.35,.3v.99h.85v-1.06c0-.5-.27-.71-1.05-.71h-1.69Zm-2.08,0c-.68,0-.95,.23-.95,.74v.35c0,.44,.27,.68,.89,.68h1.79v-.47h-1.62c-.16,0-.22-.05-.22-.16v-.07h1.28c.44,0,.69-.14,.69-.47v-.08c0-.3-.15-.51-.9-.51h-.96Zm-2.72,1.76h.85v-1.16c0-.08,.03-.13,.13-.13h.66v-.48h-.94c-.5,0-.7,.19-.7,.5v1.26h0Zm-2.89-1.76v.47h1.59c.16,0,.21,.05,.21,.17v.07h-1.28c-.53,0-.7,.22-.7,.5v.07c0,.39,.31,.5,.92,.5h.94c.66,0,.95-.15,.95-.67v-.41c0-.44-.27-.68-.89-.68h-1.75v-.02Zm-5.05,.98c0,.56,.28,.78,1.02,.78h1.61v-.48h-1.49c-.18,0-.3-.08-.3-.33v-.15c0-.23,.11-.32,.29-.32h1.49v-.48h-1.63c-.72,0-1,.3-1,.79v.2h.01Zm2.88-1.27V3.54h1.84v-.48h-.99V1.49s-.85,0-.85,0ZM3.38,2.64V1.49h-.48c-.3,0-.47,.12-.64,.26L0,3.54H1.03l1.5-1.21v1.21h.7l1.45-1.22v1.22h.86V1.49h-.47c-.25,0-.43,.1-.61,.25l-1.08,.9h0Z" fill="#ffffff"></path>
                  <path d="M23.31,0c-.47,0-1.14,.14-1.86,.4-.6,.22-1.2,.5-1.84,.91,.38-.15,1.12-.33,1.73-.33,.83,0,1.42,.32,.55,1.43,2.56-1.68,2.49-2.41,1.42-2.41" fill="#ffffff"></path>
                </svg>
              </div>
            </div>
            <Swiper
              effect={'fade'}
              modules={[EffectFade]}
              onSlideChange={(swiper) => {
                toggleRootClass();
                activeSlide(swiper.activeIndex);
              }}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
                activeSlide(0);
              }}
            >
              <SwiperSlide data-slide="0" className="h-screen">
                <nav className="absolute bottom-20 flex inset-x-0 items-center justify-center z-20">
                  <ul className="md:flex items-center">
                    <li><a href="#" className="block font-thin mb-px mr-px py-4 text-center text-sm tracking-wide uppercase w-[220px]">Formula 1</a></li>
                    <li><a href="#" className="block font-thin mb-px mr-px py-4 text-center text-sm tracking-wide uppercase w-[220px]">IndyCar</a></li>
                    <li><a href="#" className="block font-thin mb-px mr-px py-4 text-center text-sm tracking-wide uppercase w-[220px]">Extreme E</a></li>
                    <li><a href="#" className="block font-thin mb-px mr-px py-4 text-center text-sm tracking-wide uppercase w-[220px]">Esports</a></li>
                  </ul>
                </nav>
              <video 
                src="/mclaren/racing.mp4" 
                width="100%" 
                height="100%" 
                preload="auto" 
                playsInline
                muted
                poster="/mclaren/racing.webp"></video>
               
                <div className="countdown absolute bottom-0 h-1 w-full z-10"><span className="absolute bg-white block h-full right-0 top-0" style={{ width: "100%" }}></span></div>
              </SwiperSlide>
              <SwiperSlide data-slide="1" className="h-screen">
                <nav className="absolute bottom-20 flex inset-x-0 items-center justify-center z-20">
                  <ul className="md:flex items-center">
                    <li><a href="#" className="block font-thin mb-px mr-px py-4 text-center text-sm tracking-wide uppercase w-[220px]">Discover Automotive</a></li>
                  </ul>
                </nav>
              <video 
                src="/mclaren/automotive.mp4" 
                width="100%" 
                height="100%" 
                preload="auto" 
                playsInline
                muted
                poster="/mclaren/automotive.webp"></video>
              
                <div className="countdown absolute bottom-0 h-1 w-full z-10"><span className="absolute bg-white block h-full right-0 top-0" style={{ width: "100%" }}></span></div>
              </SwiperSlide>
              <SwiperSlide data-slide="2" className="h-screen">
                <nav className="absolute bottom-20 flex inset-x-0 items-center justify-center z-20">
                  <ul className="md:flex items-center">
                    <li><a href="#" className="block font-thin mb-px mr-px py-4 text-center text-sm tracking-wide uppercase w-[220px]">Visit Group</a></li>
                    <li><a href="#" className="block font-thin mb-px mr-px py-4 text-center text-sm tracking-wide uppercase w-[220px]">Investors</a></li>
                  </ul>
                </nav>
              <video 
                src="/mclaren/group.mp4" 
                width="100%" 
                height="100%" 
                preload="auto" 
                playsInline
                muted
                poster="/mclaren/group.webp"></video>
               
                <div className="countdown absolute bottom-0 h-1 w-full z-10"><span className="absolute bg-white block h-full right-0 top-0" style={{ width: "100%" }}></span></div>
              </SwiperSlide>
              <SwiperSlide data-slide="3" className="h-screen">
                <nav className="absolute bottom-20 flex inset-x-0 items-center justify-center z-20">
                  <ul className="md:flex items-center">
                    <li><a href="#" className="block font-thin mb-px mr-px py-4 text-center text-sm tracking-wide uppercase w-[220px]">Automotive</a></li>
                    <li><a href="#" className="block font-thin mb-px mr-px py-4 text-center text-sm tracking-wide uppercase w-[220px]">Racing</a></li>
                  </ul>
                </nav>
              <video 
                src="/mclaren/careers.mp4" 
                width="100%" 
                height="100%" 
                preload="auto" 
                playsInline
                muted
                poster="/mclaren/careers.webp"></video>
                
                <div className="countdown absolute bottom-0 h-1 w-full z-10"><span className="absolute bg-white block h-full right-0 top-0" style={{ width: "100%" }}></span></div>
              </SwiperSlide>
            </Swiper>

            <div className="absolute h-full left-0 pointer-events-none top-0 w-full z-30 splash inview">
              <div className="line line1">
                <img src="/mclaren/line1.svg" alt="" />
              </div>
              <div className="line line2">
                <img src="/mclaren/line2.svg" alt="" />
              </div>
              <div className="line line3">
                <img src="/mclaren/line3.svg" alt="" />
              </div>
              <div className="line line4">
                <img src="/mclaren/line4.svg" alt="" />
              </div>
              <div className="line line5">
                <img src="/mclaren/line5.svg" alt="" />
              </div>
            </div>
              
          </div>
        </section>
        <ProjectTitle
          background="mclaren"
          text={{
            title: 'white',
            text: 'white',
          }}
          content={[{
            title: 'Brand Identity Reinforcement',
            body: [
              "In the McLaren Portal redesign, the strategic use of sharp angles in navigation not only reflects the dynamic essence of the brand but also provides an intuitive and visually engaging user experience. The angles guide users seamlessly through the portal, mirroring the precision of a racing maneuver. Furthermore, the transition between carousel slides incorporates a subtle yet impactful animation, utilising the same sharp angles to add a touch of dynamism. "
            ]
          },{
            body: [
              "More than a digital gateway, the redesigned McLaren Portal serves as the entry point to all facets of the McLaren web portfolio, seamlessly connecting users to the exhilarating worlds of racing, cars, technology, and the larger McLaren Group."
            ]
          }]}
        />
      </div>
    </Layout>
  )
}

export default McLarenPortalPage
